import request from '../utils/request.js';
//如果走app相关的页面就在所有的请求路径上加上app
let baseUrl
if (location.href.includes('terminalApp') || location.href.includes('singleProductApp')) {
    baseUrl = 'App'
} else {
    baseUrl = ''
}
//获取省份 城市  地区
export function getProvince() {
    return request().get('/api/base/v1/areaselect/province/find')
}
export function getCity() {
    return request(Code).get(`/api/base/v1/areaselect/city/find?provinceCode=${Code}`)
}
export function getArea() {
    return request(Code).get(`/api/base/v1/areaselect/area/find?cityCode=${Code}`)
}
//获取镇
export function getTown(areaCode) {
    return request().get(`/api/base/v1/areaselect/town/find?areaCode=${areaCode}`)
}
//获取终端列表信息
export function masterList(params) {
    return request().post(`/api/terminal/v1/terminal${baseUrl}/list`, {
        ...params,
    })
}
//禁用终端
export function terminalDisable(params) {
    return request().post(`/api/terminal/v1/terminal${baseUrl}/disable`, {
        ...params,
    })
}
//获取扩展字段(自定义参数)
export function customParameters(funCode) {
    return request().get(`/api/terminal/v1/extra/list?funCode=${funCode}`)
}
//扩展字段新增
export function addCustomParameters(params) {
    return request().post('/api/terminal/v1/extra/save', {
        ...params,
    })
}
//扩展字段编辑
export function editCustomParameters(params) {
    return request().post('/api/terminal/v1/extra/update', {
        ...params,
    })
}
//营业执照识别
export function licenseIdentification(url) {
    return request().get(`/api/terminal/v1/terminal${baseUrl}/getLicense?imageUrl=${url}`)
}
//新增终端
export function addTerminal(params) {
    return request().post(`/api/terminal/v1/terminal${baseUrl}/save`, {
        ...params,
    })
}
//终端详情显示
export function getTerminalDetails(id) {
    return request().get(`/api/terminal/v1/terminal${baseUrl}/get?id=${id}`)
}
//编辑终端
export function editTerminal(params) {
    return request().post(`/api/terminal/v1/terminal${baseUrl}/update`, {
        ...params,
    })
}
//批量导出
export function batchExport(params) {
    //  待确认!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    return request()({
        method:'get',
        url:`/api/terminal/v1/terminal/export`,
        params
    })
}
//获取导入模板  flag 1新增  2编辑 3快速匹配
export function getImportTemplateExcel(params) {
    let flag = 1
    if (location.search.includes('type=checkstatus')) {
        flag=3
    }
    else if(location.search.includes('type=edit')){
        flag=2
    }
    return request().post(`/api/terminal/v1/terminal${baseUrl}/getTemplate?flag=${flag}`, {
        ...params,
    })
}
//开始导入  - 新增终端
export function batchImport(formData) {
    if (location.search.includes('type=checkstatus')) {
        formData.append("key", "TERMINAL_CHECK");
        return request().post(`/api/terminal/v1/terminal${baseUrl}/checkstatus`, formData)
    }
    else if(location.search.includes('type=edit')){
        formData.append("key", "UPDATE_TERMINAL");
        return request().post(`/api/terminal/v1/terminal${baseUrl}/updateBatch`, formData)
    } else if(location.search.includes('type=match')){
        formData.append("key", "TERMINAL_MATCH");
        return request().post(`/api/terminal/v1/terminal${baseUrl}/checkdata`, formData)
    }else{
        formData.append("key", "TERMINAL_INFO");
        return request().post(`/api/terminal/v1/terminal${baseUrl}/import`, formData)
    }
}
//获取导入状态
export function getImportStatus(key='TERMINAL_INFO') {
    if (location.search.includes('type=checkstatus')) {
        key='TERMINAL_CHECK'
    }
    else if(location.search.includes('type=edit')){
        key='UPDATE_TERMINAL'
    } else if (location.search.includes('type=match')) {
        key='TERMINAL_MATCH'
    }
    return request().get(`/api/terminal/v1/terminal${baseUrl}/getStatus?key=${key}`)
}
//获取错误文件
export function getErrorMsgUrl() {
    return `/api/terminal/v1/terminal${baseUrl}/getError?fileUrl=`
}
//重置导入状态
export function replaceImportStatus(key='TERMINAL_INFO') {
    if (location.search.includes('type=checkstatus')) {
        key='TERMINAL_CHECK'
    }
    else if(location.search.includes('type=edit')){
        key='UPDATE_TERMINAL'
    }else if(location.search.includes('type=match')){
        key='TERMINAL_MATCH'
    }
    return request().get(`/api/terminal/v1/terminal${baseUrl}/reload?key=${key}`)
}
//上传图片
export function imageUpload(formData) {
    return request().post(`/api/terminal/v1/terminal${baseUrl}/fileUpload`, formData)
}
//上传模板
export function templateUpload() {
    return `/api/terminal/v1/terminal${baseUrl}/templateUpload`
}



//////////////////////////////////////////////品牌相关
//获取品牌列表
export function brandList(pageNumber, pageSize) {
    return request().get(`/api/singleproduct/v1/brand/list?pageNumber=${pageNumber}&pageSize=${pageSize}`)
}
//删除品牌
export function deleteBrand(code) {
    return request().post(`/api/singleproduct/v1/brand/updateStatus?code=${code}`)
}
// 品牌->文件上传
export function upload(formData) {
    return request().post(`/api/singleproduct/v1/upload`, formData)
}
//新增品牌
export function addBrand(params) {
    return request().post(`/api/singleproduct/v1/brand/add`, {
        ...params
    })
}
//查询品牌联级
export function brandDownList(code) {
    return request().get(`/api/singleproduct/v1/brand/listSub?code=${code}`)
}
//获取全部品牌
export function allBrandList() {
    return request().get(`/api/singleproduct/v1/brand/list`)
}
//编辑品牌
export function updateBrand(params) {
    return request().post(`/api/singleproduct/v1/brand/update`, {
        ...params
    })
}
//通过名字查找品牌 
export function brandListByName(pageNumber, pageSize, barndName) {
    return request().get(`/api/singleproduct/v1/brand/list?pageNumber=${pageNumber}&pageSize=${pageSize}&brandName=${barndName}`)
  }
//////////////////////////////////////////////品类相关
//获取品类列表
export function categoryList(code) {
    return request().get(`/api/singleproduct/v1/category/list?categoryCode=${code}`)
}
//新增品类
export function addCategory(params) {
    return request().post(`/api/singleproduct/v1/category/add`, {
        ...params
    })
}
//编辑品牌及排序
export function updateCategory(params) {
    return request().post(`/api/singleproduct/v1/category/update`, {
        ...params
    })
}
//删除品类
export function deleteCategory(code) {
    return request().post(`/api/singleproduct/v1/category/updateStatus?categoryCode=${code}`)
}
//更改后的品类全部排序
export function sortCategory(params) {
    return request().post(`/api/singleproduct/v1/update/sort`, {
        ...params
    })
}
//排序专用接口
export function categoryOrder(params) {
    return request().get(`/api/singleproduct/v1/category/order?code=${params.categoryCode}&afterSort=${params.afterSort}`)
}


//---------------------> 单品相关
//单品列表
export function listSingleProduct(params) {
    return request().post(`/api/singleproduct/v1/product${baseUrl}/list`, {
        ...params
    })
}
//批量或者单个禁用单品
export function disableSingleProductById(params) {
    return request().post(`/api/singleproduct/v1/product${baseUrl}/disable`, {
        ...params,
    })
}
//批量导出单品
export function exportSingleProductList(params) {
    return request().post(`/api/singleproduct/v1/product${baseUrl}/export`, {
        ...params,
    })
}
//单品反显
export function getSingleProductById(id) {
    return request().get(`/api/singleproduct/v1/product${baseUrl}/get?id=${id}`)
}
//根据子品牌查品牌关系
export function externalBrandRelation(code) {
    return request().get(`/api/singleproduct/v1/brand/listSub?code=${code}`)
}
//根据子品类查品类关系
export function externalCategoryRelation(code) {
    return request().get(`/api/singleproduct/v1/externalCategoryRelation?code=${code}`)
}
//获取单品模板
export function getSingleProductImportTemplateExcel(params) {
    return request().post(`/api/singleproduct/v1/product${baseUrl}/getTemplate`, {
        ...params,
    })
}
//获取单品更新模板
export function getUpdateTemplate(params) {
    return request().post(`/api/singleproduct/v1/product${baseUrl}/getUpdateTemplate`, {
        ...params,
    })
}
//获取品牌品类模板
export function getBrandImportTemplateExcel(data) {
    return request().get(`/api/singleproduct/v1/product${baseUrl}/getTemplate/${data}`)
}
//品牌批量导入
export function brandImport(formData) {
    return request().post(`/api/singleproduct/v1/brand${baseUrl}/import`, formData)
}
//品类批量导入
export function categoryImport(formData) {
    return request().post(`/api/singleproduct/v1/category${baseUrl}/import`, formData)
}
//单品批量导入
export function singleProductImport(formData) {
    return request().post(`/api/singleproduct/v1/product${baseUrl}/import`, formData)
}
//单品批量编辑
export function singleProductUpdateBatch(formData) {
    return request().post(`/api/singleproduct/v1/product${baseUrl}/updateBatch`, formData)
}
//获取单品导入状态
export function singleproductGetImportStatus(key) {
    return request().get(`/api/terminal/v1/terminal${baseUrl}/getStatus?key=${key}`)
}

//获取品牌品类导入状态
export function BrandGetImportStatus(data) {
    return request().get(`/api/terminal/v1/terminal${baseUrl}/getStatus?key=${data}`)
}
//重置导入状态
export function singleproductReplaceImportStatus(key) {
    return request().get(`/api/singleproduct/v1/product${baseUrl}/reload?key=${key}`)
}
//品牌品类重置导入状态
export function brandReplaceImportStatus(data) {
    return request().get(`/api/singleproduct/v1/product${baseUrl}/reload?key=${data}`)
}
//单品错误提示
export function singleproductGetErrorMsgUrl(key) {
    return request().get(`/api/singleproduct/v1/product${baseUrl}/getError?key=SINGLEPRODUCT`)
}
//品牌错误提示
// export function singleproductGetErrorMsgUrl(key) {
//     return request().get(`/api/singleproduct/v1/product${baseUrl}/getError?key=SINGLEPRODUCT`)
// }
//返回单品详情页面中的item
export function listSingleProductForPackage(params) {
    return request().post(`/api/singleproduct/v1/product${baseUrl}/listPackage`, {
        ...params,
    })
}
//单品编辑
export function updateSingleProductById(params) {
    return request().post(`/api/singleproduct/v1/product${baseUrl}/update`, {
        ...params,
    })
}
//新增单品
export function saveSingleProduct(params) {
    return request().post(`/api/singleproduct/v1/product${baseUrl}/save`, {
        ...params,
    })
}
//单品详情页面的单位列表
export function listBaseValueByCode() {
    return request().get(`/api/base/v1/basedataApi/listBaseValueByCode?code=PRODUCT_UNIT`)
}
//单品获取错误文件
export function productGetErrorMsgUrl() {
    return `/api/singleproduct/v1/product${baseUrl}/getError?fileUrl=`
}

///////////////////////////////////////////////////////////渠道管理相关
//直接使用了终端中的接口即可
//新增渠道
export function channelAdd(params) {
    return request().post(`/api/terminal/v1/channel/save`, {
        ...params
    })
}
//编辑渠道
export function channelUpdate(params) {
    return request().post(`/api/terminal/v1/channel/update`, {
        ...params
    })
}
//删除渠道
export function channelDelete(code) {
    return request().post(`/api/terminal/v1/channel/delete?code=${code}`)
}
//查询渠道
export function channelLists(params) {
    return request().get(`/api/terminal/v1/channel/list`, {
        params: {
            ...params,
        },
    })
}
//渠道下拉列表
export function channelSearch(params) {
    return request().get(`/api/terminal/v1/channelApi/list`, {
        params: {
            ...params,
        },
    })
}
//查询渠道树
export function channelTree() {
    return request().get(`/api/terminal/v1/channelApi/listAll`)
}
//排序专用接口
export function channelOrder(params) {
    return request().get(`/api/terminal/v1/channel/order?code=${params.channelCode}&afterSort=${params.afterSort}`)
}


//---------------------> 客户相关
//客户列表
export function listCust(params) {
    return request().post(`/api/executor/v1/cust${baseUrl}/list`, {
        ...params
    })
}
//批量或者单个禁用客户
export function disableCustById(params) {
    return request().post(`/api/executor/v1/cust${baseUrl}/updateState`, {
        ...params,
    })
}
//新增客户
export function saveCust(params) {
    return request().post(`/api/executor/v1/cust${baseUrl}/add`, {
        ...params,
    })
}
//更新客户
export function updateCust(params) {
    return request().post(`/api/executor/v1/cust${baseUrl}/update`, {
        ...params,
    })
}
//客户类型列表
export function listCustType() {
    return request().get(`/api/base/v1/basedataApi/listBaseValueByCode?code=CUST_TYPE`)
}


//---------------------> 数据权限标签相关
//全部权限
export function dataAll() {
    return request().get(`/api/tas/v1/tag/data/all`)
}
//增加
export function dataCreate(params) {
    return request().post(`/api/tas/v1/tag/data/create`, {
        ...params,
    })
}
//修改
export function dataIdUpdate(id, tagName) {
    return request().post(`/api/tas/v1/tag/data/${id}/update`, {
        tag: tagName,
    })
}
//删除
export function dataDel(id) {
    return request().delete(`/api/tas/v1/tag/data/${id}/del`)
}
//获取权限
export function dataList(tagCode, pdType) {
    return request().get(`/api/executor/v1/tag/data/list?tagCode=${tagCode}&pdType=${pdType}`)
}
//编辑权限
export function saveConfiguration(params) {
    return request().post(`/api/executor/v1/tag/data/saveConfiguration`, {
        ...params,
    })
}






